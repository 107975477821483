import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";
import APIService from "./services/api.service";
import SettingsDialog from "./components/SettingsDialog";
import Settings from "@mui/icons-material/Settings";
import Mic from "@mui/icons-material/Mic";
import Stop from "@mui/icons-material/Stop";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Chip from "@mui/material/Chip";
import { ISettings } from "./services/api.service";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const theme = createTheme({
  palette: {
    primary: { main: "#ffffff", light: "#ffffff", dark: "#ffffff", contrastText: "#ffffff" },
    secondary: { main: "#999999", light: "#999999", dark: "#999999", contrastText: "#999999" },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          color: "#555555",
        },
        colorSecondary: {
          color: "white",
        },
      },
    },
  },
});

const textFieldStyle = {
  "& .MuiInputBase-root": {
    color: "#ffffff",
    width: "60vw",
  },
  "& .MuiFormLabel-root": {
    color: "secondary.main",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "primary.main",
  },
};

function App() {
  const [showIntro, setShowIntro] = React.useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(-1);

  const [answers, setAnswers] = React.useState<String[]>([]);
  const [currentAnswer, setCurrentAnswer] = React.useState("");
  const [recommendation, setRecommendation] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [openSettings, setOpenSettings] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [settings, setSettings] = React.useState<any>([]);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showLoadingDB, setShowLoadingDB] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [lessonData, setLessonData] = React.useState({});
  const [lessonQuestions, setLessonQuestions] = React.useState({});
  const [lessonId, setLessonId] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [streaming, setStreaming] = React.useState(false);
  const [project, setProject] = React.useState("");
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const api = "https://ovl5j7wun4.execute-api.us-east-1.amazonaws.com/dev";

  useEffect(() => {
    setCurrentAnswer(transcript);
  }, [transcript]);

  useEffect(() => {
    console.log("use effect");
    const fetchSettings = async () => {
      try {
        const result = await APIService.getSettings();
        console.log("result", result);
        setSettings(result);
        console.log("settings", settings);
      } catch (error) {
        console.error("Error fetching settings:", error);
      } finally {
        setLoading(true);
      }
    };

    // Llamar a la función para obtener los datos
    fetchSettings();
  }, []);

  const processStream = async function (stream: any, setFunction: any) {
    let content = "";
    for await (const chunk of stream) {
      console.log(chunk.object);
      if (chunk.object === "chat.completion.chunk") {
        // Display data from the chunk
        content += chunk.choices[0]?.delta?.content || "";
        setFunction(content);
      } else {
        //this is not being called
        console.log("done streaming");
        doneGPT();
      }
    }
  };

  const handleClickOpenSettings = () => {
    setOpenSettings(true);
  };

  const handleCloseSettings = async (settings: ISettings | null) => {
    if (settings) {
      setSettings(settings);
      setCurrentQuestionIndex(0);
      setShowLoader(true);
      await APIService.saveSettings(settings);
      setShowLoader(false);
      setOpenSettings(false);
    }
    if (settings === null) {
      setOpenSettings(false);
    }
  };

  const start = (step: number) => {
    setShowIntro(false);
    setCurrentQuestionIndex(step);
    setAnswers([]);
    setCurrentAnswer("");
    setRecommendation("");
  };

  const next = () => {
    if (currentQuestionIndex >= 0) {
      answers.push(currentAnswer);
      setAnswers([...answers]);
    }

    if (currentQuestionIndex < settings.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrentAnswer("");
      resetTranscript();
    } else {
      end();
    }
  };

  const doneGPT = () => {
    console.log("done");
    setStreaming(false);
  };

  const checkId = async (id: any) => {
    const responseLesson = await fetch(`${api}/intermediateLesson`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apiKey: "4AeCksf4Ak",
      },
      body: JSON.stringify({ id }),
    });

    if (responseLesson.status === 200) {
      const lessonGenerated = await responseLesson.json();
      setRecommendation(lessonGenerated.lesson);
      setLessonData(lessonGenerated);
      setShowLoading(false);
    } else {
      setTimeout(() => {
        checkId(id);
      }, 5000);
    }
  };

  const end = async () => {
    setShowLoading(true);
    setStreaming(true);
    let prompt = settings.prompt + "\n";
    for (let i = 0; i < settings.questions.length; i++) {
      prompt += settings.questions[i] + "\n" + answers[i] + "\n";
    }

    console.log(prompt);
    //const suggestedText = await APIService.chatGPTStream(prompt);
    //const suggestedText = await APIService.fetchLesson(prompt);

    const data = { prompt, api: "AOI", project: project };

    try {
      const response = await fetch(`${api}/generateLessonId`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: "4AeCksf4Ak",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      console.log("result", result);

      console.log("Success:", result);

      setLessonId(result.id);

      checkId(result.id);
    } catch (error) {
      console.error("Error al enviar mensaje:", error);
    }

    //processStream(suggestedText, setRecommendation);
  };

  const saveLesson = async () => {
    try {
      setShowLoadingDB(true);
      const response = await fetch(`${api}/createLesson`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: "4AeCksf4Ak",
        },
        body: JSON.stringify(lessonData),
      });

      const result = await response.json();
      console.log("Success:", result);
      setShowLoadingDB(false);
      setOpen(true);
    } catch (error) {
      console.error("Error al guardar la lección:", error);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const createTitle = async () => {
    const sugguestedTitle = await APIService.chatGPTStream(
      "Create un titulo para una appp de lecciones aprendidas que usa AI y Generative AI"
    );
    processStream(sugguestedTitle, setTitle);
  };

  const renderQuestions = () => {
    if (currentQuestionIndex < 0) {
      return (
        <>
          <p>¿A qué proyecto se relaciona esta lección aprendida?</p>
          <TextField
            color="primary"
            sx={textFieldStyle}
            label="Digita número y nombre del proyecto"
            multiline
            rows={1}
            maxRows={4}
            value={project}
            onChange={(ev) => {
              if (!listening) setProject(ev.currentTarget.value);
            }}
          />
          <Button onClick={() => next()} variant="outlined">
            {currentQuestionIndex == settings.questions.length - 1 ? (
              <>Finalizar</>
            ) : (
              <>Próxima Pregunta</>
            )}
          </Button>
        </>
      );
    }
    return (
      <>
        {showLoading && (
          <Box
            sx={{
              display: "flex",
              marginTop: 0,
              marginLeft: 2,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <p style={{ fontSize: "12px", fontStyle: "italic" }}>
              Generando lección aprendida...
            </p>
          </Box>
        )}

        <p>{settings.questions[currentQuestionIndex]}</p>
        <Box component="form" noValidate autoComplete="off">
          <div>
            <TextField
              color="primary"
              sx={textFieldStyle}
              label="Usa tus propias palabras para responder la pregunta"
              multiline
              rows={4}
              maxRows={4}
              value={currentAnswer}
              onChange={(ev) => {
                if (!listening) setCurrentAnswer(ev.currentTarget.value);
              }}
            />
          </div>
          <Button onClick={() => next()} variant="outlined">
            {currentQuestionIndex == settings.questions.length - 1 ? (
              <>Finalizar</>
            ) : (
              <>Próxima Pregunta</>
            )}
          </Button>
        </Box>
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Chip
          className="settingsChip"
          color="primary"
          icon={<Settings />}
          label="Settings"
          onClick={() => {
            handleClickOpenSettings();
          }}
        />
        <Alert severity="warning">
          Esta app es una prueba creada por el ITE/TechLab, para más información
          contactar techlab@iadb.org.
        </Alert>
        <Alert severity="warning">
          This app is a test created by the ITE/Techlab, for more information
          contact techlab@iadb.org.
        </Alert>
        {showIntro ? (
          <header className="App-header">
            Lesson GPT
            <p style={{ width: "60vw", textAlign: "left" }}>{title}</p>
            <Button onClick={() => start(-1)} variant="outlined">
              Start
            </Button>
          </header>
        ) : (
          <div className="main">
            {recommendation ? (
              <>
                {showLoadingDB && (
                  <Box
                    sx={{
                      display: "flex",
                      marginTop: 0,
                      marginLeft: 2,
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                    <p style={{ fontSize: "12px", fontStyle: "italic" }}>
                      Guardando lección aprendida...
                    </p>
                  </Box>
                )}
                <Snackbar
                  open={open}
                  onClose={handleClose}
                  autoHideDuration={6000}
                >
                  <Alert severity="success" sx={{ width: "100%" }}>
                    Lección aprendida guardada correctamente
                  </Alert>
                </Snackbar>
                <TextField
                  color="primary"
                  sx={textFieldStyle}
                  multiline
                  label="Lección aprendida sugerida"
                  value={recommendation}
                />
                {/* <Button onClick={() =>  end()} variant="outlined">
                  Regenerar Leccion
                </Button> */}
                <Button onClick={() => saveLesson()} variant="outlined">
                  Guardar lección
                </Button>
                <Button onClick={() => start(-1)} variant="outlined">
                  Crear otra lección
                </Button>
                <Button onClick={() => start(0)} variant="outlined">
                  Crear otra lección para el mismo proyecto
                </Button>
              </>
            ) : (
              <>{renderQuestions()}</>
            )}
          </div>
        )}
        <div>
          <p>Micrófono: {listening ? "on" : "off"}</p>
          <Button
            onClick={() => {
              SpeechRecognition.startListening({
                continuous: true,
                language: "es",
              });
            }}
          >
            <Mic />
            Grabar
          </Button>
          <Button onClick={SpeechRecognition.stopListening}>
            <Stop />
            Detener
          </Button>
          <Button onClick={resetTranscript}>
            <RestartAlt />
            Comenzar de nuevo
          </Button>
          <p>
            La funcionalidad de grabacion de voz esta en Beta y solo configurada
            para español
          </p>
        </div>
      </div>

      {loading ? (
        <SettingsDialog
          settingsValue={settings}
          open={openSettings}
          onClose={handleCloseSettings}
          loader={showLoader}
        />
      ) : (
        <></>
      )}
    </ThemeProvider>
  );
}

export default App;
