import OpenAI from "openai";

export interface ISettings {
  questions: string[];
  openAI_APIKey: string;
  prompt: string, 
  api: string | null,
}

const defaultSettings = {
  questions: [
    "En tu proyecto, ¿qué hecho o evento ha abierto una oportunidad para aprender? ",
    "¿Cuál fue la implicación de este hecho o evento para la ejecución (productos) del proyecto?",
    "¿Cuál crees que puede ser la consecuencia de este hecho o evento en los resultados (impacto) del proyecto?",
    "¿Qué has aprendido de esta experiencia y qué consejos o recomendaciones accionables tienes para futuros equipos de proyecto que puedan enfrentarse a una circunstancia similar?",
  ],
  openAI_APIKey: "",
  prompt:
    "Crea una lección aprendida utilizando las siguientes respuestas. Asegúrate de que las respuestas a las preguntas estén muy bien explicadas. La leccion aprendida que generes solo debe tener en su estructura un hallazgo y una recomendación. Para el hallazgo usa verbos en tiempo pasado como por ejemplo: permitió, facilitó, identificó, determinó, verificó, provocó, analizó, comparó, dificultó, descubrió, encontró, antes de, previo a, etc. y para la recomendacion inicia con una frase que haga una transición entre el hallazgo y la recomendacion, como por ejemplo: En ocasiones futuras...en circunstancias similares… se recomienda...se sugiere...sería útil..., etc. y utiliza verbos en tiempo presente como por ejemplo: elaborar, llevar a cabo, definir, analizar, evaluar, documentar, priorizar, recomendar, implementar, monitorear, comunicar, reflexionar, etc. \n",
  api: "AOI",
};
class APIService {
  private static openai: OpenAI | null = null;

  // constructor(openAIapiKey: string | null) {
  //   if(openAIapiKey)
  //     this.openai = new OpenAI({
  //       apiKey: openAIapiKey,
  //       dangerouslyAllowBrowser: true, // WARNING ONLY FOR TEST
  //     });
  // }

  public static setAPIkey(key: string) {
    this.openai = new OpenAI({
      apiKey: key,
    });
  }

  public static getAPIKey() {
    return this.openai?.apiKey;
  }

  public static fetchLesson(prompt: string) {
    const api = "";
  }

  public getQuestions() {
    return [
      "En tu proyecto, ¿qué hecho o evento ha abierto una oportunidad para aprender? ",
      "¿Cuál fue la implicación de este hecho o evento para la ejecución (productos) del proyecto? ",
      "¿Cuál crees que puede ser la consecuencia de este hecho o evento en los resultados (impacto) del proyecto?",
      "¿Qué has aprendido de esta experiencia y qué consejos o recomendaciones accionables tienes para futuros equipos de proyecto que puedan enfrentarse a una circunstancia similar?",
    ];
  }

  public static async createImage(promtp: string) {
    const response = await this.openai?.images.generate({
      prompt: promtp,
      n: 1,
      size: "512x512",
    });
    console.log(response);
    return response?.data[0].url;
  }

  public static async chatGPT(prompt: string) {
    const completion = await this.openai?.chat.completions.create({
      messages: [{ role: "user", content: prompt }],
      model: "gpt-3.5-turbo",
      temperature: 0,
    });
    return completion?.choices[0].message.content;
  }

  public static async chatGPTStream(prompt: string) {
    console.log("prompt");
    try {
      if (!this.openai) {
        return "null";
      }
      const completion = await this.openai?.chat.completions.create({
        messages: [{ role: "user", content: prompt }],
        model: "gpt-3.5-turbo",
        stream: true,
      });
      return completion;
    } catch {
      return "Por favor configurar el API key en Settings";
    }
  }

  static async getSettings() {
    //const settingsString = localStorage.getItem("Settings");
    //if (settingsString) return JSON.parse(settingsString);
    //else return defaultSettings;

    const api =
      "https://ovl5j7wun4.execute-api.us-east-1.amazonaws.com/dev/lessonQuestions";

    try {
      const response = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: "4AeCksf4Ak",
        },
      });

      const result = await response.json();

      return result;
    } catch (error) {
      console.error("Error al obtener las nuevas settings:", error);
      return defaultSettings;
    }
  }

  public static async saveSettings(settings: any) {
    //localStorage.setItem("Settings", JSON.stringify(settings));
    const api =
      "https://ovl5j7wun4.execute-api.us-east-1.amazonaws.com/dev/saveLessonQuestions";

    try {
      const response = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apiKey: "4AeCksf4Ak",
        },
        body: JSON.stringify(settings),
      });

      const result = await response.json();
      console.log("Success saving:", result);
    } catch (error) {
      console.error("Error al guardar las nuevas configuraciones:", error);
    }
  }

  public static getProjects() {
    return ["OP-AR-0001", "OP-PE-0003", "OP-BR-0005", "OP-AR-0006"];
  }

  public static getApiOptions() {
    return ["AOI", "OI"];
  }

  public static getActivities(projectNumer: string) {
    return ["Actividad 1", "Actividad 2", "Actividad 3", "Actividad 4"];
  }
}

export default APIService;
