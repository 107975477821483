import "./SettingsDialog.css";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { ISettings } from "../services/api.service";
import APIService from "../services/api.service";
import React from "react";

export interface SettingsProps {
  open: boolean;
  loader: boolean;
  settingsValue: ISettings;
  onClose: (value: ISettings | null) => void;
}

function SettingsDialog(props: SettingsProps) {
  const { onClose, settingsValue, open, loader } = props;
  const [settings, setSettings] = React.useState(settingsValue);

  const textFieldStyle = {
    "& .MuiInputBase-root": {
      color: "#ffffff",
      width: "60vw",
    },
    "& .MuiFormLabel-root": {
      color: "secondary.main",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "primary.main",
    },
  };

  const handleClose = () => {
    onClose(null);
  };

  const handleSave = () => {
    onClose(settings);
  };

  const handleListItemClick = (value: ISettings) => {
    onClose(value);
  };

  const saveQuestions = (index: number, question: string) => {
    settings.questions[index] = question;
    setSettings({ ...settings });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Settings</DialogTitle>
      <div className="dialog">
        <List className="list">
          <ListItem>
            <p>
              These settings will be store in your browser session. If you start
              using another computer or browser you will need to configure them
              again.
            </p>
          </ListItem>
          <ListItem>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={APIService.getApiOptions()}
              sx={textFieldStyle}
              value={settings.api}
              onChange={(event: any, newValue: string | null) => {
                settings.api = newValue;
                setSettings({ ...settings });
              }}
              renderInput={(params) => (
                <TextField {...params} label="API" variant="standard" />
              )}
            />
          </ListItem>
          {
            <ListItem>
              <TextField
                className="textField"
                color="secondary"
                label="Prompt to create final text"
                variant="standard"
                multiline
                maxRows={4}
                value={settings.prompt}
                onChange={(ev) => {
                  settings.prompt = ev.currentTarget.value;
                  setSettings({ ...settings });
                }}
              />
            </ListItem>
          }
          {settingsValue.questions.map((q, index) => {
            return (
              <ListItem key={"q" + index}>
                <TextField
                  className="textField"
                  color="secondary"
                  label={"Question " + (index + 1)}
                  variant="standard"
                  multiline
                  maxRows={4}
                  value={(q = settingsValue.questions[index])}
                  onChange={(ev) => {
                    saveQuestions(index, ev.currentTarget.value);
                  }}
                />
              </ListItem>
            );
          })}
        </List>
        {loader && (
          <Box
            sx={{
              display: "flex",
              marginTop: 0,
              marginLeft: 2,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress color="success" />
            <p style={{ fontSize: "12px", fontStyle: "italic" }}>
              Guardando...
            </p>
          </Box>
        )}
        <Button
          className="button"
          variant="outlined"
          color="secondary"
          onClick={handleClose}
        >
          Discard
        </Button>
        <Button
          className="button"
          variant="outlined"
          color="secondary"
          onClick={handleSave}
        >
          Save Settings
        </Button>
      </div>
    </Dialog>
  );
}

export default SettingsDialog;
